<template>
  <div class="achievements-list-component">
    <div class="container">
      <div class="achievements-list-wrapper">
        <div class="achievements-list-heading">
          <p class="section-title">TREINOS EM UMA SEMANA</p>
        </div>
        <div class="achievements-list d-flex justify-content-start align-items-center">
          <div class="achievements-item" :class="[ this.weekAchievements >= 3 ? 'active' : '']">
            <div class="achievements-item-icon">
              <trophyIcon/>
            </div>
            <p class="achievements-item-title">3 Treinos</p>
          </div>
          <div class="achievements-item" :class="[ this.weekAchievements >= 6 ? 'active' : '']">
            <div class="achievements-item-icon">
              <trophyIcon/>
            </div>
            <p class="achievements-item-title">6 Treinos</p>
          </div>
          <div class="achievements-item" :class="[ this.weekAchievements >= 9 ? 'active' : '']">
            <div class="achievements-item-icon">
              <trophyIcon/>
            </div>
            <p class="achievements-item-title">9 Treinos</p>
          </div>
          <div class="achievements-item" :class="[ this.weekAchievements >= 12 ? 'active' : '']">
            <div class="achievements-item-icon">
              <trophyIcon/>
            </div>
            <p class="achievements-item-title">12 Treinos</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import trophyIcon from "@/components/svg/trophy-icon.vue";

export default {
  name: "achievements-list",
  components: {
    trophyIcon,
  },
  props: {
    weekAchievements: {
      required: true,
    },
  },
}
</script>