<template>
  <div class="achievements-list-component">
    <div class="container">
      <div class="achievements-list-wrapper">
        <div class="achievements-list-heading">
          <p class="section-title">SÉRIE DE TREINOS</p>
        </div>
        <div class="achievements-list d-flex justify-content-start align-items-center">
          <div class="achievements-item active">
            <div class="achievements-item-icon">
              <trophyIcon/>
            </div>
            <p class="achievements-item-title">2 Semanas Seguidas</p>
          </div>
          <div class="achievements-item">
            <div class="achievements-item-icon">
              <trophyIcon/>
            </div>
            <p class="achievements-item-title">3 Semanas Seguidas</p>
          </div>
          <div class="achievements-item">
            <div class="achievements-item-icon">
              <trophyIcon/>
            </div>
            <p class="achievements-item-title">4 Semanas Seguidas</p>
          </div>
          <div class="achievements-item">
            <div class="achievements-item-icon">
              <trophyIcon/>
            </div>
            <p class="achievements-item-title">5 Semanas Seguidas</p>
          </div>
          <div class="achievements-item">
            <div class="achievements-item-icon">
              <trophyIcon/>
            </div>
            <p class="achievements-item-title">6 Semanas Seguidas</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import trophyIcon from "@/components/svg/trophy-icon.vue";

export default {
  name: "achievements-list",
  components: {
    trophyIcon,
  }
}
</script>