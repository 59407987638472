<template>
  <div class="dashboard progress-achievements-page">
    <div class="progress-page-top">
      <tabs/>
      <div class="progress-count-wrapper section-padding">
        <div class="container">
          <div class="progress-count d-xl-flex justify-content-start align-items-center">
            <p class="progress-count-total">{{ achievements.totalYear }}</p>
            <div class="progress-count-labels">
              <p class="progress-count-title">Total de Atividades</p>
              <!--<p class="progress-count-minutes">2000 minutos</p>-->
            </div>
          </div>
        </div>
      </div>
      <chartComponent/>
      <weekAchievements :weekAchievements="achievements.totalWeek" />
      <!--<workoutAchievements/>-->
    </div>
  </div>
</template>

<script>
import tabs from "@/components/progress/progress-tabs.vue";
import chartComponent from "@/components/chart-component.vue";
import weekAchievements from "@/components/achievements/week-achievements-list.vue";
import workoutAchievements from "@/components/achievements/workout-achievements-list.vue";
import axios from "axios";

export default {
  name: "progress-achievements",
  data() {
    return {
      achievements: [],
    }
  },
  metaInfo () {
    return {
      title: this.$root.translations.progresso,
    }
  },
  components : {
    tabs,
    chartComponent,
    weekAchievements,
    workoutAchievements
  },
  mounted() {
    let authKey = window.$cookies.get("authKey");

    axios
        .get(process.env.VUE_APP_API_URL + 'achievements',{
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.achievements = response.data.model;
        })
        .catch((e) => {
          this.errors.push(e);
        });
  },
  created() {
    this.$root.pageTitle = this.$root.translations.progresso;
  }
}

</script>