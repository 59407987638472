<script>
import { Bar } from 'vue-chartjs'
import axios from "axios";

export default {
  extends: Bar,
  data() {
    return {
      achievements: [],
    }
  },
  mounted() {
    let authKey = window.$cookies.get("authKey");

    axios
        .get(process.env.VUE_APP_API_URL + 'achievements',{
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.achievements = response.data.model;

          this.renderChart(
              {
                labels: ['JAN', 'FEB', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
                datasets: [{
                  barThickness: 20,
                  maxBarThickness: 20,
                  lineTension: 0,
                  borderWidth: 1,
                  borderColor: '#b9b9b9',
                  backgroundColor: '#b9b9b9',
                  data: [this.achievements.graphYear.Jan, this.achievements.graphYear.Fev, this.achievements.graphYear.Mar, this.achievements.graphYear.Abr, this.achievements.graphYear.Mai, this.achievements.graphYear.Jun, this.achievements.graphYear.Jul, this.achievements.graphYear.Ago, this.achievements.graphYear.Set, this.achievements.graphYear.Out, this.achievements.graphYear.Nov, this.achievements.graphYear.Dez]
                }]
              },
              {
                legend: { //hides the legend
                  display: false,
                },
                scales: {
                  yAxes: [{
                    display: false,
                    ticks: {
                      beginAtZero: true
                    },
                    gridLines: {
                      display: false
                    }
                  }],
                  xAxes: [{
                    display: true,
                    ticks: {
                      beginAtZero: true,
                      fontSize: 16,
                      fontStyle: 'bold',
                      fontColor: '#000',
                    },
                    gridLines: {
                      display: false
                    }
                  }]
                },
                tooltips: {
                  enabled: false,
                },
                responsive: true,
                maintainAspectRatio: false,
                height: 120,
              }
          )
        })
        .catch((e) => {
          this.errors.push(e);
        });

  }
};
</script>