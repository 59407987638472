<template>
  <div class="box-chart-component section-padding">
    <div class="container">
      <div class="box-chart-heading">
        <p class="section-title">Actividade</p>
        <div class="box-chart-wrapper">
          <div class="box-chart">
            <chart/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "@/components/chart.vue";

export default {
  name: "chart-component",
  components: {
    chart
  }
};
</script>